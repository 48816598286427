import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from '../components/layout';
import SEO from '../components/seo';
export const _frontmatter = {
  "title": "404",
  "path": "/404"
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = () => <>
        <SEO title="404: Not found" />
        <Layout>
            <h1>Page Not Found</h1>
            <p>This page doesn't exist.</p>
        </Layout>
    </>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      